import React from 'react';

import Layout from '../components/layouts/en';
import PageCookie from '../components/page-cookie';


const Cookies = () => <Layout>
  <PageCookie />
</Layout>

export default Cookies