import styled from 'styled-components';

export const PageSection = styled.div`
  padding-top: 50px;
  padding-bottom: 40px;
  h1 {
    color: #3e434a;
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 28px;
    }
    @media (max-width: 575px) {
      font-size: 26px;
    }
  }
  
  h2,
  h3 {
    color: #3e434a;
    font-size: 26px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  a {
    color: #8c989f;
    text-decoration: underline;
    &:hover,
    &:focus {
      &:before {
        display: none;
      }
    }
  }
`